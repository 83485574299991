<template>
  <base-page class="p-4 px-16">
    <div class="flex mb-4">
      <button
        class="bg-red-200 p-2 flex items-center rounded-xl ml-2"
        :class="enableDelete ? 'text-red-600' : 'text-red-300'"
        :disabled="!enableDelete"
        title="Elimina evses"
        @click="deleteEvse">
        <span class="material-icons">delete_forever</span>
      </button>
      <button class="w-8 h-8 self-end" title="Attiva eliminazione" @click="enableDelete = true" v-if="!enableDelete">
        <span class="material-icons">lock</span>
      </button>
      <button class="w-8 h-8 self-end" title="Attiva eliminazione" @click="enableDelete = false" v-if="enableDelete">
        <span class="material-icons">lock_open</span>
      </button>
    </div>
    <div class="grid grid-rows-2 grid-flow-col gap-1">
      <div class="mb-4 flex" v-for="(el, index) in evses" :key="el.evse_id">
        <!-- inizio card -->
        <div class="mr-12 bg-gray-100 p-4 rounded-2xl w-full hover:bg-gray-300 focus:bg-gray-300">
          <!-- header -->
          <div>
            <div class="flex justify-center">
              <p class="font-bold text-xl">{{ el.evse_id ? el.evse_id : '-' }}</p>
            </div>
            <div class="flex justify-between mt-4">
              <p class="">Stato Evse</p>
              <div>
                <span class="flex">
                  <p :class="evseStatus(el.evse_status) + ' font-bold ml-4 '">
                    {{ el.evse_status }}
                  </p>
                  <button
                    v-show="evseShow[index]"
                    @click="evseShow[index] = !evseShow[index]"
                    class="material-icons ml-2">
                    arrow_drop_down
                  </button>
                  <button
                    v-show="!evseShow[index]"
                    @click="evseShow[index] = !evseShow[index]"
                    class="material-icons ml-2">
                    arrow_drop_up
                  </button>
                </span>
                <base-select
                  v-if="!evseShow[index]"
                  title=""
                  :data="evseECarStatus"
                  @change="changeStatus($event, el.evse_id)" />
              </div>
            </div>
          </div>
          <!-- fine header -->

          <!-- body -->
          <div class="mt-8 flex justify-center">
            <div class="text-left text-gray-600">
              <p>Stato su App</p>
              <p>Id presa</p>
              <p>Tipologia</p>
              <p>Stato eCar</p>
            </div>
            <div class="ml-6 font-bold">
              <div class="flex">
                <p>{{ el.active ? t('station.socketActiveStatus') : t('station.socketInactiveStatus') }}</p>
                <button
                  @click="changePlugStatus(el.active, el.evse_id)"
                  :class="
                    (el.active ? 'bg-green-900 ' : 'bg-red-900 ') +
                    'ml-2 self-end w-4 h-4  border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500'
                  " />
              </div>
              <p>{{ el.plug_id ? el.plug_id : '-' }}</p>
              <p>{{ el.type ? el.type : '-' }}</p>
              <p :class="evseStatus(el.plug_status)">{{ el.plug_status }}</p>
            </div>
          </div>
          <!-- fine body -->

          <!-- footer -->
          <div class="mt-8 flex justify-between">
            <div class="text-center">
              <p class="self-center mr-4 text-xs">ultimo evse update</p>
              <p class="font-bold text-sm" v-html="parseField(el.evse_updated, 'datetime')" />
            </div>
            <div class="text-center">
              <p class="self-center mr-4 text-xs">ultimo plug update</p>
              <p class="font-bold text-sm" v-html="parseField(el.evse_updated, 'datetime')" />
            </div>
          </div>
          <!-- fine footer -->
        </div>
      </div>
    </div>
  </base-page>
</template>

<script>
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { computed, onMounted, ref, reactive } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { Utils } from '@/Utils/utils';
  export default {
    setup() {
      //init vue stuffs
      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      //get evses
      const evseShow = reactive([]);
      const evses = computed(() => {
        store.getters.evses?.forEach(() => {
          evseShow.push({
            show: false,
          });
        });
        return store.getters.evses;
      });

      onMounted(() => {
        store.dispatch('getEvses', route.params.id);
      });

      function changePlugStatus(status, plugId) {
        const paramsObj = {
          id: plugId,
          status: { status: status ? 0 : 1 },
        };
        store.dispatch('changePlugStatus', paramsObj).then(() => router.go());
      }

      const evseStatus = (status) => {
        switch (status) {
          case 'AVAILABLE':
            return 'text-green-700';
          case 'OCCUPIED':
            return 'text-blue-700';
          case 'UNLOCKED':
            return 'text-green-700';
          case 'FAULT':
            return 'text-yellow-700';
          default:
            return 'text-red-800';
        }
      };

      const parseField = (field, format) => Utils.formatData(field, format);
      const enableDelete = ref(false);
      const deleteEvse = () => {
        store.dispatch('deleteEvses', { id: route.params.id }).then(() => router.go());
      };
      const changeStatus = (event, evseId) => {
        store.dispatch('changeEvseStatus', { evseId: evseId, state: event.target.value }).then(() => router.go());
      };
      const evseECarStatus = ['AVAILABLE', 'RESERVED', 'OCCUPIED', 'OUTOFSERVICE', 'EVSENOTFOUND', 'UNKNOWN'];
      const openChangeStatusSelect = ref(false);

      return {
        evses,
        enableDelete,
        evseECarStatus,
        openChangeStatusSelect,
        evseShow,
        changeStatus,
        deleteEvse,
        changePlugStatus,
        t: useI18n().t,
        evseStatus,
        parseField,
      };
    },
  };
</script>

<style></style>
