<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage"></base-list-table>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getCompleteRechargesList', payload);
        store.dispatch('getRechargesInProgressList', payload);
      }

      const completeRechargeList = computed(() => store.getters.completeRechargeList);

      const inProgressCount = computed(() => store.getters.rechargeInProgressList.length);

      const linkConstructor = (el) => {
        console.log(el.session_id);
        const newPage = window.open('', '_blank');
        newPage.document.write('Attendere...');
        store.dispatch('getEcarRecharge', { id: el.session_id, inProgress: false }).then((response) => {
          newPage.location.href = `https://oc.ecar.siemens.com/OCWeb2/#/rechargeDetails/${response[0]?.rechargeSessionId}`;
        });
      };

      const structure = {
        title: 'views.recharges.endedRecharges',

        timeFields: [
          { label: 'views.stations.rechargeStart', key: 'inizio_ricarica' },
          { label: 'views.stations.rechargeEnd', key: 'fine_ricarica' },
        ],
        summaryFields: [
          {
            type: 'SUM',
            label: 'views.recharges.providedKWH',
            field: 'kw_consumati',
            unit: ' kWh',
          },
          {
            type: 'SUM',
            label: 'views.recharges.totalTakings',
            field: 'total_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'views.recharges.stripeTakings',
            field: 'card_amount',
            unit: ' €',
          },
          {
            type: 'SUM',
            label: 'views.recharges.walletTakings',
            field: 'wallet_amount',
            unit: ' €',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctUsers',
            field: 'user_id',
            unit: 'users',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctStations',
            field: 'station_id',
            unit: 'station.plurals',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.totalRecharges',
            field: 'session_id',
            unit: 'sessions',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.recharges.noKWHRecharges',
            field: 'session_id',
            valueField: 'kw_consumati',
            value: '0',
            unit: 'sessions',
          },
        ],
        data: [
          {
            title: 'views.recharges.session',

            fields: [
              {
                placeholder: '@',
                name: 'id',
                class: 'text-primary-400',
              },
              {
                name: 'session_id',
              },
              {
                placeholder: 'Presa: ',
                name: 'numero_presa',
              },
            ],
            link: { idField: 'id', path: '/recharge/recharges/:id' },
            span: 2,
          },
          {
            title: 'views.recharges.chargingStation',

            fields: [
              {
                placeholder: '@',
                name: 'station_id',
                class: 'text-primary-400',
              },
              {
                name: 'station_name',
              },
              {
                name: 'station_address',
              },
            ],
            span: 3,
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          {
            title: 'user.title',

            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-400',
              },
              {
                name: 'user_name',
              },
            ],
            span: 2,
            link: { idField: 'user_id', path: '/user/users/:id' },
          },
          {
            title: 'startDate',

            field: 'inizio_ricarica',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'endDate',

            field: 'fine_ricarica',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'views.recharges.providedPower',

            format: 'kwh',
            field: 'kw_consumati',
            span: 1,
          },
          {
            title: 'payments.stripeAmount',

            field: 'card_amount',
            format: 'euro',
            span: 1,
          },
          {
            title: 'payments.walletAmount',

            field: 'wallet_amount',
            format: 'euro',
            span: 1,
          },
          {
            title: 'payments.totalAmount',

            field: 'total_amount',
            format: 'euro',
            span: 1,
          },
          {
            title: 'views.stations.link',
            link: {
              function: linkConstructor,
              placeholder: 'views.stations.ecarLink',
            },
            span: 1,
          },
        ],
      };
      return {
        data: completeRechargeList,
        inProgressCount,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
