<template>
  <div>
    <div class="flex mb-4">
      <button class="px-2 py-1 rounded-xl bg-primary-700 text-gray-200" @click="filterRecharge = !filterRecharge">
        {{ !filterRecharge ? 'Mostra SOLO le non presenti su eCar' : 'Mostra tutte' }}
      </button>
    </div>
    <div class="flex justify-end items-center">
      <base-button icon="troubleshoot" @click="fixAnomalies">Controllo anomalie</base-button>
      <base-button icon="refresh" class="ml-2" @click="updateParameters">Aggiorna parametri</base-button>
    </div>
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed, ref } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();
      const refreshPage = (payload) => {
        store.dispatch('getEcarRecharges', payload);
        store.dispatch('getRechargesInProgressList', payload);
      };
      const filterRecharge = ref(false);
      const rechargesInProgress = computed(() => {
        const recharges = store.getters.rechargeInProgressList;
        let finalArray = recharges;

        if (filterRecharge.value) {
          let tmpArray = [];
          const eCarRecharges = store.getters.eCarRecharges;
          let tmpBool = false;
          finalArray?.forEach((recharge) => {
            tmpBool = false;
            eCarRecharges?.forEach((eCarRecharge) => {
              if (recharge.session_id == eCarRecharge.session_id) {
                tmpBool = true;
              }
            });
            if (!tmpBool) tmpArray.push({ ...recharge, sendCdr: true });
          });

          finalArray = tmpArray;
        }
        return finalArray;
      });
      const endRecharge = (el) => {
        store.dispatch('forcedShutdownRecharge', {
          evse_id: el.evse_id,
          session_id: el.session_id,
        });
      };

      const sendCdr = (sessionId) => store.dispatch('sendCdr', sessionId);
      const insertSessionId = (evseId) => store.dispatch('addSessionId', { evseId });
      const structure = {
        title: 'views.recharges.rechargesInProgress',

        summaryFields: [
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.sessions',
            field: 'session_id',
            unit: 'recharges',
          },
          {
            type: 'SUM',
            label: 'views.recharges.providedKWH',
            field: 'consumed_energy',
            unit: 'kWh',
          },
          {
            type: 'SUM',
            label: 'views.recharges.instantaneousPower',
            field: 'charging_power',
            unit: 'kW',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctUsers',
            field: 'user_id',
            unit: 'users',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctStations',
            field: 'station_id',
            unit: 'station.plurals',
          },
        ],
        data: [
          {
            title: 'views.recharges.session',

            fields: [
              {
                name: 'session_id',
                class: 'text-primary-300',
              },
              {
                placeholder: 'station.socket',
                name: 'numero_presa',
              },
            ],
            span: 2,
          },
          {
            title: 'views.recharges.chargingStation',

            fields: [
              {
                placeholder: '@',
                name: 'station_id',
                class: 'text-primary-300',
              },
              {
                name: 'station_name',
              },
              {
                name: 'station_address',
              },
            ],
            span: 3,
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          {
            title: 'user.title',

            fields: [
              {
                placeholder: '@',
                name: 'user_id',
                class: 'text-primary-300',
              },
              {
                name: 'user_name',
              },
            ],
            span: 2,
            link: { idField: 'user_id', path: '/user/users/:id' },
          },
          {
            title: 'startDate',

            field: 'inizio_ricarica',
            format: 'datetime',
            span: 1,
          },
          {
            label: 'views.recharges.providedKWH',

            format: 'kwh',
            field: 'consumed_energy',
            span: 1,
          },
          {
            title: 'views.recharges.power',

            format: 'power',
            field: 'charging_power',
            span: 1,
          },
          {
            title: 'Termina ricarica',
            button: {
              title: 'buttons.endRecharge',
              function: endRecharge,
            },
            span: 1,
          },
          {
            title: 'CDR',
            buttons: {
              fields: [
                {
                  title: 'buttons.sendCdr',
                  function: sendCdr,
                  returned_val: 'session_id',
                  condition_val: true,
                },
              ],
              condition: 'sendCdr',
            },
            span: 1,
          },
          {
            title: '+ Session_id',
            buttons: {
              fields: [
                {
                  title: 'buttons.insert',
                  function: insertSessionId,
                  returned_val: 'evse_id',
                  condition_val: '',
                },
              ],
              condition: 'session_id',
            },
            span: 1,
          },
        ],
      };

      const fixAnomalies = () =>
        store.dispatch('fixAnomalies').then(() => store.dispatch('getRechargesInProgressList'));
      const updateParameters = () => store.dispatch('updateRechargeParameters');

      return {
        data: rechargesInProgress,
        structure,
        filterRecharge,
        fixAnomalies,
        updateParameters,
        refreshPage,
      };
    },
  };
</script>

<style></style>
