<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage"></base-list-table>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getRechargesWithoutCDR', payload);
      }

      const rechargesWithoutCDR = computed(() => store.getters.rechargesWithoutCDR);

      const linkConstructor = (el) => {
        store.dispatch('loaderShow');
        console.log(el);
        var data = JSON.stringify(el);
        store.dispatch('sendFakeCDR', data).then(() => {
          refreshPage();
        });
      };

      const structure = {
        title: 'views.recharges.endedRecharges',

        timeFields: [
          { label: 'views.stations.rechargeStart', key: 'session_start' },
          { label: 'views.stations.rechargeEnd', key: 'session_end' },
        ],
        summaryFields: [
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctUsers',
            field: 'partner_session_id',
            unit: 'users',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctStations',
            field: 'evse_id',
            unit: 'station.plurals',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.totalRecharges',
            field: 'session_id',
            unit: 'sessions',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.recharges.noKWHRecharges',
            field: 'session_id',
            valueField: 'consumed_energy',
            value: '0',
            unit: 'sessions',
          },
        ],
        data: [
          {
            title: 'session_id',
            field: 'session_id',
            span: 2,
          },
          {
            title: 'partner_session_id',
            field: 'partner_session_id',
            span: 1,
          },
          {
            title: 'evseId',
            field: 'evse_id',
            span: 1,
          },
          {
            title: 'startDate',
            field: 'session_start',
            format: 'datetime',
            span: 1,
          },
          {
            title: 'endDate',
            field: 'session_end',
            format: 'datetime',
            span: 1,
          },

          {
            title: 'views.recharges.providedPower',
            format: 'kwh',
            field: 'consumed_energy',
            span: 1,
          },
          {
            title: 'views.stations.link',
            link: {
              function: linkConstructor,
              placeholder: 'views.stations.sendFakeCDR',
            },
            span: 1,
          },
        ],
      };

      return {
        data: rechargesWithoutCDR,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
