<template>
  <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';

  export default {
    components: {},
    setup() {
      const store = useStore();
      // init station page
      function refreshPage() {
        store.dispatch('getSiemensStations');
      }

      //change station value
      const siemensStations = computed(() => store.getters.siemensStations);

      // table
      const structure = {
        title: 'views.stations.title',

        summaryFields: [
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.available',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '1',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.partiallyOccupied',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '102',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.occupied',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '101',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.partiallyOperational',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '104',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.notReachable',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '21',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.notWorking',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '3',
          },
          {
            type: 'COUNT_DISTINCT_WITH_VALUE',
            label: 'views.stations.installed',
            field: 'cuId',
            unit: 'station.plurals',
            valueField: 'statusId',
            value: '2',
          },
        ],
        data: [
          {
            title: 'views.stations.idAndSerial',

            fields: [
              {
                placeholder: '@',
                name: 'cuCode',
                class: 'text-primary-400',
              },
              {
                name: 'sn',
              },
            ],
            span: 1,
            link: { idField: 'cuCode', path: '/recharge/stations/siemens/:id' },
          },
          {
            title: 'status',
            field: 'statusId',
            parseField: {
              2: 'Installata',
              4: 'Dismessa',
              1: 'Disponibile',
              101: 'Occupata',
              102: 'Parzialmente occupata',
              104: 'Parzialmente operativa',
              3: 'Non funzionante',
              21: 'Non raggiungibile',
            },
            span: 1,
          },
          {
            title: 'address.body',

            field: 'cuName',
            span: 1,
          },
          {
            title: 'latitude',
            field: 'localization',
            subField: 'latitude',
            span: 1,
          },
          {
            title: 'longitude',
            field: 'localization',
            subField: 'longitude',
            span: 1,
          },
          {
            title: 'type',
            field: 'cuModelCd',
            span: 1,
          },
          {
            title: 'station.sWVersion',
            field: 'actualSwVersion',
            span: 1,
          },
          {
            title: 'station.activeAt',
            field: 'dataCommisioning',
            format: 'unix_to_datetime',
            span: 1,
          },
          {
            title: 'views.stations.link',
            link: {
              link: 'https://oc.ecar.siemens.com/OCWeb2/#/cuDetails/',
              name: 'cuCode',
              placeholder: 'views.stations.ecarLink',
            },
            span: 1,
          },
        ],
      };

      return {
        data: siemensStations,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
