<template>
  <div>
    <base-list-table :data="data" :structure="structure" :refreshFunction="refreshPage" />
  </div>
</template>

<script>
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  export default {
    components: {},
    setup() {
      const store = useStore();

      function refreshPage(payload) {
        store.dispatch('getEcarRecharges', payload);
      }

      const rechargesInProgress = computed(() => store.getters.eCarRecharges);

      const linkConstructor = (el) => {
        const newPage = window.open('', '_blank');
        newPage.document.write('Attendere...');
        store.dispatch('getEcarRecharge', { id: el.session_id, inProgress: true }).then((response) => {
          newPage.location.href = `https://oc.ecar.siemens.com/OCWeb2/#/rechargeDetails/${response[0].rechargeSessionId}`;
        });
      };

      const structure = {
        title: 'views.recharges.rechargesInProgress',
        summaryFields: [
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.sessions',
            field: 'session_id',
            unit: 'recharges',
          },
          {
            type: 'SUM',
            label: 'views.recharges.providedKWH',
            field: 'consumed_energy',
            unit: 'kWh',
          },
          {
            type: 'SUM',
            label: 'views.recharges.instantaneousPower',
            field: 'charging_power',
            unit: 'kW',
          },
          {
            type: 'COUNT_DISTINCT',
            label: 'views.recharges.distinctStations',
            field: 'station_id',
            unit: 'station.plurals',
          },
        ],
        data: [
          {
            title: 'views.recharges.session',
            fields: [
              {
                name: 'session_id',
                class: 'text-primary-400',
              },
              {
                placeholder: 'Presa: ',
                name: 'numero_presa',
              },
            ],
            span: 1,
          },
          {
            title: 'views.recharges.chargingStation',
            fields: [
              {
                placeholder: '@',
                name: 'station_id',
                class: 'text-primary-400',
              },
              {
                name: 'station_name',
              },
              {
                name: 'station_address',
              },
            ],
            span: 1,
            link: { idField: 'station_id', path: '/recharge/stations/:id' },
          },
          {
            title: 'views.recharges.providedKWH',
            format: 'kwh',
            field: 'consumed_energy',
            span: 1,
          },
          {
            title: 'views.recharges.power',
            format: 'power',
            field: 'charging_power',
            span: 1,
          },
          {
            title: 'startDate',
            field: 'inizio_ricarica',
            span: 1,
          },
          {
            title: 'views.timeEntries.elapsedTime',
            field: 'elapsed_time',
            span: 1,
          },
          {
            title: 'views.stations.link',
            link: {
              function: linkConstructor,
              placeholder: 'views.stations.ecarLink',
            },
            span: 1,
          },
        ],
      };

      return {
        data: rechargesInProgress,
        structure,
        refreshPage,
      };
    },
  };
</script>

<style></style>
