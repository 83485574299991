import { ApiCaller } from '@/Utils/utils';

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    sendNotifications: (_, payload) => ApiCaller.POST(`/sendNotifications`, payload),
  },
};
